import "mapbox-gl/dist/mapbox-gl.css";
import type { LayersList } from "@deck.gl/core";
import { ArcLayer, IconLayer } from "@deck.gl/layers";
import DeckGL from "@deck.gl/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo } from "react";
import { Map as GMap } from "react-map-gl";
import type { Leg } from "./client";
import { useBottomMapControls } from "./useBottomMapControls";
import { useTopMapControls } from "./useTopMapControls";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const useLegsAsArcs = (legs: Leg[]) =>
	useMemo(
		() =>
			legs.map((x) => {
				const allText: string[] = [];
				const locationText = `🚩:${x.collection.name}\n👉:${x.delivery.name}`;
				allText.push(locationText);
				if (x.collectionDate && x.deliveryDate) {
					const collectionDateText = dayjs(x.collectionDate).format(
						"YYYY-MM-DD HH:mm",
					);
					const deliveryDateText = dayjs(x.deliveryDate).format(
						"YYYY-MM-DD HH:mm",
					);
					const timingText = `\n🕒:${collectionDateText} - ${deliveryDateText}`;
					allText.push(timingText);
					const durationText = `⏱:${dayjs
						.duration(dayjs(x.deliveryDate).diff(x.collectionDate))
						.humanize()}`;
					allText.push(durationText);
				}
				allText.push(`\n📦:${x.legStatusName}`);
				allText.push(`👤:${x.customerName}`);
				return {
					name: allText.join("\n"),
					from: {
						lat: x.collection.latitude,
						lng: x.collection.longitude,
					},
					to: {
						lat: x.delivery.latitude,
						lng: x.delivery.longitude,
					},
					rgbColor: [
						Math.random() * 255,
						Math.random() * 255,
						Math.random() * 255,
					] as [number, number, number],
				};
			}),
		[legs],
	);

export const useLayers = (
	arcsData: ReturnType<typeof useLegsAsArcs>,
	locations: ReturnType<typeof useTopMapControls>[2],
): LayersList =>
	useMemo(
		() => [
			new ArcLayer<(typeof arcsData)[number]>({
				id: "arc-layer",
				data: arcsData,
				getWidth: 5,
				opacity: 0.5,
				getSourcePosition: (x) => [x.from.lng, x.from.lat],
				getTargetPosition: (x) => [x.to.lng, x.to.lat],
				getSourceColor: (x) => x.rgbColor,
				getTargetColor: (x) => x.rgbColor,
				getHeight: 0.5,
				pickable: true,
			}),
			new IconLayer<(typeof locations)[number]>({
				id: "icon-layer",
				data: locations,
				getColor: () => [255, 0, 0],
				getIcon: () => "marker",
				getPosition: (d) => [d.longitude, d.latitude],
				getSize: () => 20,
				iconAtlas:
					"https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
				iconMapping:
					"https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.json",
				pickable: true,
			}),
		],
		[arcsData, locations],
	);

export const App = () => {
	const [topMapControls, legs, locations] = useTopMapControls();
	const [bottomMapControls] = useBottomMapControls();
	const arcsData = useLegsAsArcs(legs);
	const layers = useLayers(arcsData, locations);
	return (
		<DeckGL
			initialViewState={{ longitude: -3, latitude: 57, zoom: 6, pitch: 30 }}
			layers={layers}
			getTooltip={(x) => x.object?.name}
			controller
			style={{ height: "calc(100vh - calc(40px + 40px))", top: "40px" }}
		>
			<GMap
				mapStyle="mapbox://styles/mapbox/navigation-night-v1"
				mapboxAccessToken="pk.eyJ1IjoiY2huZWF1IiwiYSI6ImNraXg0ZzZrYzF2aGoyc240dmEzZmlycWEifQ.SdSDK1OdemvA9afl56UKJA"
			/>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					justifyContent: "space-between",
				}}
			>
				{topMapControls}
				{bottomMapControls}
			</div>
		</DeckGL>
	);
};
