import { useFetchEvaluationResults } from "./client";
import { useDateSelector } from "./useDateSelector";
import { useSelectBusinessUnit } from "./useSelectBusinessUnit";

export const useBottomMapControls = () => {
	const [buSelector, businessUnit] = useSelectBusinessUnit();
	const [dateSelector, date] = useDateSelector();
	const {
		data: evaluation,
		refetch,
		isFetching,
	} = useFetchEvaluationResults(businessUnit?.id, date);
	const element = (
		<div
			style={{
				display: "flex",
				gap: 16,
				backgroundColor: "lightgray",
				padding: 8,
				justifyContent: "space-evenly",
			}}
		>
			{buSelector}
			{dateSelector}
			<button type="button" onClick={() => refetch()}>
				{isFetching ? "⏬" : "🔃"}
			</button>
			<div>
				Simulate: {evaluation?.simulate.trips?.length ?? 0} vs Best:{" "}
				{evaluation?.best.trips?.length ?? 0}
			</div>
		</div>
	);
	return [element] as const;
};
