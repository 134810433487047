import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import type { Position, Vehicle } from "../../server/ai/common";
import type {
	Evaluation,
	EvaluationResult,
	Trip,
} from "../../server/ai/evaluationResult";
import type { ScenarioRequest } from "../../server/ai/scenarioRequest";
import { clientDomain } from "../client";
import { rgbColors } from "./colors";

dayjs.extend(utc);

const AI_URL = clientDomain;
const TLS_URL = clientDomain;

export const fetchScenarios = async (
	dates: [Date, Date],
): Promise<ScenarioRequest[]> => {
	const from = dayjs.utc(dates[0]).startOf("day");
	const to = dayjs.utc(dates[1]).endOf("day");
	const allDates: Date[] = [];
	for (
		let start = from;
		start.isBefore(to);
		start = start.add(30, "hours").startOf("day")
	) {
		allDates.push(start.toDate());
	}
	const requests = await Promise.all(
		allDates.map((date) => fetchScenario(date)),
	);
	return requests;
};

export const fetchScenario = async (date: Date): Promise<ScenarioRequest> => {
	const dateString = dayjs.utc(date).startOf("day").format("YYYY-MM-DD");
	const response = await fetch(`${TLS_URL}/scenarios/${dateString}`);
	const json = (await response.json()) as ScenarioRequest;
	// convert dates
	json.clock = dayjs.utc(json.clock as unknown as string).toDate();
	for (const item of json.items ?? []) {
		if (!item) continue;
		item.from.date = dayjs
			.utc((item.from.date as unknown as string) ?? "0000-00-00T00:00:00Z")
			.toDate();
		item.to.date = dayjs
			.utc((item.to.date as unknown as string) ?? "0000-00-00T00:00:00Z")
			.toDate();
	}
	for (const vehicle of json.vehicles ?? []) {
		if (!vehicle) continue;
		vehicle.enabled = true;
	}
	for (let i = 0; i < json.vehicles.length; i++) {
		const vehicle = json.vehicles[i];
		if (!vehicle) continue;
		const rgbColor = rgbColors[i % rgbColors.length];
		if (!rgbColor) continue;
		vehicle.rgbColor = rgbColor;
	}
	return json;
};

export const fetchAllTrips = async (
	scenarioRequests: ScenarioRequest[],
	isSimulate = false,
): Promise<EvaluationResult[]> => {
	const requests = scenarioRequests.map((scenarioRequest) =>
		fetchTrips(scenarioRequest, isSimulate),
	);
	const responses = await Promise.all(requests);
	return responses;
};

export const fetchAllTripsIgnoreFails = async (
	scenarioRequests: ScenarioRequest[],
): Promise<EvaluationResult[]> => {
	const requests = scenarioRequests.map(async (scenarioRequest) => {
		try {
			return await fetchTrips(scenarioRequest);
		} catch (e) {
			return await fetchTrips(scenarioRequest, true);
		}
	});
	const responses = await Promise.all(requests);
	return responses;
};

const fetchTrips = async (
	scenarioRequest: ScenarioRequest,
	isSimulate = false,
): Promise<EvaluationResult> => {
	const scenarioRequestCopy = _.cloneDeep(scenarioRequest);
	scenarioRequestCopy.timeLimit = 24;
	scenarioRequestCopy.distancer = "Haversine";
	scenarioRequestCopy.pather = "LinearPather";
	scenarioRequestCopy.scorer = "FuelConsumption";
	scenarioRequestCopy.inserter = "Best";
	scenarioRequestCopy.constraints = ["Capacity", "Constraints", "Time"];
	if (isSimulate) {
		scenarioRequestCopy.inserter = "Simulate";
		scenarioRequestCopy.constraints = [];
	}
	for (const vehicle of scenarioRequestCopy.vehicles) {
		vehicle.id += dayjs.utc(scenarioRequestCopy.clock).format("YYYY-MM-DD");
	}
	const response = await fetch(`${AI_URL}/trips`, {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(scenarioRequestCopy),
	});
	const json = (await response.json()) as EvaluationResult;
	if (_.has(json, "message"))
		throw new Error(
			(_.get(json, "message") as string | undefined) ?? "Unknown error",
		);
	// convert dates
	for (const trip of json.trips ?? []) {
		for (const event of trip.events ?? []) {
			if (!event) continue;
			event.from.date = dayjs
				.utc((event.from.date as unknown as string) ?? "0000-00-00T00:00:00Z")
				.toDate();
			event.to.date = dayjs
				.utc((event.to.date as unknown as string) ?? "0000-00-00T00:00:00Z")
				.toDate();
		}
		trip.vehicle.rgbColor = scenarioRequestCopy.vehicles.find(
			(f) => f.id === trip.vehicle.id,
		)?.rgbColor ?? [0, 0, 0];
		for (const event of trip.events) {
			event.distance *= 0.621371;
		} // HARD CODED CONVERSION
	}
	for (const evaluation of json.evaluations) {
		if (!evaluation.distanceTraveled) continue;
		evaluation.distanceTraveled *= 0.621371;
	} // HARD CODED CONVERSION
	if (json.totals.distanceTraveled) json.totals.distanceTraveled *= 0.621371; // HARD CODED CONVERSION
	return json;
};

// separate camelCase words with spaces
export const camelCaseToWords = (str: string): string => {
	return str
		.replace(/([A-Z])/g, " $1")
		.replace(/^./, (str) => str.toUpperCase())
		.trim();
};

const stopsFromTrips = (trips: Trip[]): Position[][] => {
	const allStops: Position[][] = [];
	const itemStates = new Map<string, boolean>();
	for (const trip of trips) {
		const stops: Position[] = [];
		for (const itemOrder of trip.itemsOrder) {
			const item = trip.items[itemOrder];
			if (!item) continue;
			const isDelivery = itemStates.get(item.id) ?? false;
			itemStates.set(item.id, true);
			const { rgbColor } = trip.vehicle;
			if (isDelivery)
				stops.push({ rgbColor, lat: item.to.lat, lng: item.to.lng });
			else stops.push({ rgbColor, lat: item.from.lat, lng: item.from.lng });
		}
		allStops.push(stops);
	}
	return allStops;
};

export type MapData = {
	from: Position;
	to: Position;
	rgbColor: [number, number, number];
};

export const positionsFromTrips = (trips: Trip[]): MapData[] => {
	const positions: MapData[] = [];
	const allStops = stopsFromTrips(trips);
	for (const stops of allStops) {
		for (let i = 0; i < stops.length - 1; i++) {
			const from = stops[i];
			const to = stops[i + 1];
			if (!from || !to) continue;
			const { rgbColor } = from;
			if (!rgbColor) continue;
			positions.push({ from, to, rgbColor: rgbColor });
		}
	}
	return positions;
};

export const allDisplayMode = ["value", "percent", "delta"] as const;
export type DisplayMode = (typeof allDisplayMode)[number];

export const allMapMode = ["arcs", "paths"] as const;
export type MapMode = (typeof allMapMode)[number];

export type EvaluationWithExtra = Omit<Evaluation, "vehicles"> & {
	driverId: string;
	driverName: string;
	jobs: number;
	isWorking: boolean;
};

export const EvaluationResultToEvaluationWithName = (
	evaluationResult: EvaluationResult | null | undefined,
	otherEvaluationResult?: EvaluationResult | null,
): EvaluationWithExtra[] => {
	const evaluations: EvaluationWithExtra[] = [];
	if (!evaluationResult) return evaluations;
	// keep track of driverIds in a map
	const driverIds = new Map<string, boolean>();
	for (const [i, evaluation] of evaluationResult.evaluations.entries()) {
		const driverId = evaluationResult.trips?.[i]?.vehicle.id ?? "";
		const driverName = evaluationResult.trips?.[i]?.vehicle.name ?? "";
		driverIds.set(driverId, true);
		const jobs = evaluationResult.trips?.[i]?.items.length ?? 0;
		const distanceTraveled = evaluation.distanceTraveled ?? 0;
		evaluations.push({
			...evaluation,
			driverName,
			driverId,
			distanceTraveled,
			jobs,
			isWorking: true,
		});
	}
	// add all other drivers
	if (otherEvaluationResult) {
		for (const [i] of otherEvaluationResult.evaluations.entries()) {
			const driverId = otherEvaluationResult.trips?.[i]?.vehicle.id ?? "";
			if (driverIds.has(driverId)) continue;
			const driverName = otherEvaluationResult.trips?.[i]?.vehicle.name ?? "";
			evaluations.push({
				driverId,
				driverName,
				distanceTraveled: 0,
				revenue: 0,
				profit: 0,
				driverWage: 0,
				fuelCost: 0,
				expenses: 0,
				fuelConsumption: 0,
				hoursTraveled: 0,
				hoursWorked: 0,
				maintenanceCost: 0,
				resourceCost: 0,
				jobs: 0,
				isWorking: false,
			});
		}
	}
	// sort by driver name and then by driver id
	evaluations.sort((a, b) => {
		if (a.driverName < b.driverName) return -1;
		if (a.driverName > b.driverName) return 1;
		if (a.driverId < b.driverId) return -1;
		if (a.driverId > b.driverId) return 1;
		return 0;
	});
	return evaluations;
};

export const getAllVehicles = (
	scenarioRequests: ScenarioRequest[],
): Vehicle[] => {
	const vehicles: Map<string, Vehicle> = new Map();
	for (const scenarioRequest of scenarioRequests) {
		for (const vehicle of scenarioRequest.vehicles) {
			vehicles.set(vehicle.id, vehicle);
		}
	}
	return Array.from(vehicles.values());
};

export const flattenEvaluationResults = (
	evaluationResults: EvaluationResult[],
): EvaluationResult => {
	const evaluations: Evaluation[] = [];
	const trips: Trip[] = [];
	const totals: Evaluation = {
		distanceTraveled: 0,
		revenue: 0,
		profit: 0,
		driverWage: 0,
		expenses: 0,
		fuelCost: 0,
		fuelConsumption: 0,
		hoursTraveled: 0,
		hoursWorked: 0,
		maintenanceCost: 0,
		resourceCost: 0,
		vehicles: 0,
	};
	for (const evaluationResult of evaluationResults) {
		evaluations.push(...evaluationResult.evaluations);
		trips.push(...(evaluationResult.trips ?? []));
		totals.distanceTraveled += evaluationResult.totals.distanceTraveled;
		totals.revenue += evaluationResult.totals.revenue;
		totals.profit += evaluationResult.totals.profit;
		totals.driverWage += evaluationResult.totals.driverWage;
		totals.expenses += evaluationResult.totals.expenses;
		totals.fuelCost += evaluationResult.totals.fuelCost;
		totals.fuelConsumption += evaluationResult.totals.fuelConsumption;
		totals.hoursTraveled += evaluationResult.totals.hoursTraveled;
		totals.hoursWorked += evaluationResult.totals.hoursWorked;
		totals.maintenanceCost += evaluationResult.totals.maintenanceCost;
		totals.resourceCost += evaluationResult.totals.resourceCost;
		totals.vehicles += evaluationResult.totals.vehicles;
	}
	return { evaluations, trips, totals };
};
