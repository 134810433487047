import dayjs from "dayjs";
import { useMemo, useState } from "react";

export const useDateSelector = ({ label = "Date", isStart = true } = {}) => {
	const defaultDate = useMemo(
		() =>
			isStart ? dayjs().startOf("d").toDate() : dayjs().endOf("d").toDate(),
		[isStart],
	);
	const [date, setDate] = useState<Date>(defaultDate);
	const selector = (
		<div>
			<label>
				{label}:
				<input
					type="date"
					value={dayjs(date).format("YYYY-MM-DD")}
					onChange={(e) =>
						setDate(
							isStart
								? dayjs(e.target.value).startOf("d").toDate()
								: dayjs(e.target.value).endOf("d").toDate(),
						)
					}
				/>
			</label>
		</div>
	);
	return [selector, date] as const;
};
