import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	SwapOutlined,
} from "@ant-design/icons";
import { Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useCallback, useMemo } from "react";
import type { EvaluationResult } from "../../server/ai/evaluationResult";
import {
	type DisplayMode,
	EvaluationResultToEvaluationWithName,
	type EvaluationWithExtra,
} from "./common";

type DriversTableProps = {
	results: [EvaluationResult | null, EvaluationResult | null];
	index: number;
	displayMode?: DisplayMode;
};

export const KpiDriversTable = ({
	results,
	index,
	displayMode,
}: DriversTableProps) => {
	const rawResult = results[index];
	const rawOtherResult = results[1 - index];
	const result = useMemo(
		() => EvaluationResultToEvaluationWithName(rawResult, rawOtherResult),
		[rawResult, rawOtherResult],
	);
	const otherResult = useMemo(
		() => EvaluationResultToEvaluationWithName(rawOtherResult),
		[rawOtherResult],
	);
	const otherResultByDriverId = useMemo(
		() =>
			otherResult.reduce(
				(acc, cur) => Object.assign(acc, { [cur.driverId]: cur }),
				{} as Record<string, EvaluationWithExtra>,
			),
		[otherResult],
	);
	const renderer = useCallback(
		(
			key: keyof EvaluationWithExtra,
			suffix = "",
			reversed = false,
			decimals = 1,
		) =>
			(value: number, record: EvaluationWithExtra) => {
				let newValue = value;
				let newSuffix = suffix;
				if (!record.isWorking) return "-";
				const otherRecord = otherResultByDriverId[record.driverId];
				const otherValue = (otherRecord?.[key] ?? 0) as number;
				const isBetter = newValue === otherValue ? null : newValue < otherValue;
				let color = isBetter != null ? (isBetter ? "green" : "red") : "black";
				if (reversed)
					color = isBetter != null ? (isBetter ? "red" : "green") : "black";
				const prefix =
					isBetter != null ? (
						isBetter ? (
							<ArrowDownOutlined />
						) : (
							<ArrowUpOutlined />
						)
					) : (
						<SwapOutlined />
					);
				if (displayMode === "percent") {
					newValue = otherValue ? -(100 - (newValue / otherValue) * 100) : 0;
					newSuffix = "%";
				}
				if (displayMode === "delta") newValue -= otherValue;
				return (
					<div style={{ color, whiteSpace: "nowrap" }}>
						{prefix} {newValue.toFixed(decimals)} {newSuffix}
					</div>
				);
			},
		[displayMode, otherResultByDriverId],
	);
	const columns: ColumnsType<EvaluationWithExtra> = useMemo(
		() => [
			{
				title: "Name",
				dataIndex: "driverName",
				key: "driverName",
				render: (x: string) => (
					<Tooltip
						mouseEnterDelay={0}
						mouseLeaveDelay={0}
						title={x}
					>{`${x.slice(0, 3)}...`}</Tooltip>
				),
			},
			{
				title: "Jobs",
				dataIndex: "jobs",
				key: "jobs",
				render: renderer("jobs", "", true, 0),
			},
			// { title: "Hours traveled", dataIndex: "hoursTraveled", key: "hoursTraveled", render: renderer("hoursTraveled", "h") },
			{
				title: "Distance traveled",
				dataIndex: "distanceTraveled",
				key: "distanceTraveled",
				render: renderer("distanceTraveled", "mi"),
			},
			{
				title: "Hours worked",
				dataIndex: "hoursWorked",
				key: "hoursWorked",
				render: renderer("hoursWorked", "h"),
			},
			// { title: "Fuel consumed", dataIndex: "fuelConsumption", key: "fuelConsumption", render: renderer("fuelConsumption", "l") },
			{
				title: "Fuel cost",
				dataIndex: "fuelCost",
				key: "fuelCost",
				render: renderer("fuelCost", "£"),
			},
			{
				title: "Maintenance cost",
				dataIndex: "maintenanceCost",
				key: "maintenanceCost",
				render: renderer("maintenanceCost", "£"),
			},
			{
				title: "Resource cost",
				dataIndex: "resourceCost",
				key: "resourceCost",
				render: renderer("resourceCost", "£"),
			},
			{
				title: "Driver wage",
				dataIndex: "driverWage",
				key: "driverWage",
				render: renderer("driverWage", "£"),
			},
			{
				title: "Revenue",
				dataIndex: "revenue",
				key: "revenue",
				render: renderer("revenue", "£"),
			},
			{
				title: "Profit",
				dataIndex: "profit",
				key: "profit",
				render: renderer("profit", "£", true),
			},
		],
		[renderer],
	);
	return (
		<Table //
			dataSource={result}
			columns={columns}
			size="small"
			bordered
			pagination={false}
			rowKey={(x) => x.driverId}
		/>
	);
};
