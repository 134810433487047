import type { LayersList, ViewStateChangeParameters } from "@deck.gl/core";
import { ArcLayer, LineLayer } from "@deck.gl/layers";
import DeckGL from "@deck.gl/react";
import { Divider, Switch } from "antd";
import { useEffect, useMemo } from "react";
import { Map as GMap } from "react-map-gl";
import type { Trip } from "../../server/ai/evaluationResult";
import { type MapData, type MapMode, positionsFromTrips } from "./common";

const MAPBOX_ACCESS_TOKEN =
	"pk.eyJ1IjoiY2huZWF1IiwiYSI6ImNraXg0ZzZrYzF2aGoyc240dmEzZmlycWEifQ.SdSDK1OdemvA9afl56UKJA";

export type TripsMapProps = {
	allTrips: [Trip[], Trip[]];
	index: number;
	onViewStateChange?: (
		viewState: ViewStateChangeParameters["viewState"],
	) => void;
	viewState?: ViewStateChangeParameters["viewState"];
	onMapModesChange?: (mapModes: MapMode[]) => void;
	mapModes?: MapMode[];
};

export const TripsMap = ({
	allTrips,
	index,
	onViewStateChange,
	viewState,
	onMapModesChange,
	mapModes,
}: TripsMapProps) => {
	const trips = useMemo(() => allTrips[index] ?? [], [allTrips, index]);
	useEffect(() => {
		const a = setInterval(() => window.dispatchEvent(new Event("resize")), 400);
		const b = setTimeout(() => clearInterval(a), 2000);
		return () => [a, b].forEach(clearInterval);
	}, []);
	// https://deck.gl/docs/api-reference/layers/line-layer
	const linesData: MapData[] = useMemo(
		() =>
			trips.flatMap((t) => {
				const x: MapData[] = [];
				for (const event of t.events) {
					if (!event.path) continue;
					for (let i = 0; i < event.path.length - 1; i++) {
						const from = event.path?.[i];
						const to = event.path?.[i + 1];
						const rgbColor = t.vehicle.rgbColor;
						if (!from || !to || !rgbColor) continue;
						x.push({ from, to, rgbColor } satisfies MapData);
					}
				}
				return x;
			}),
		[trips],
	);
	const arcsData = useMemo(() => positionsFromTrips(trips), [trips]);
	const layers: LayersList = useMemo(() => {
		const layers: LayersList = [];
		if (mapModes?.includes("arcs")) {
			layers.push(
				new ArcLayer({
					id: "arc-layer",
					data: arcsData,
					getWidth: 5,
					opacity: 0.5,
					getSourcePosition: (x: MapData) => [x.from.lng, x.from.lat],
					getTargetPosition: (x: MapData) => [x.to.lng, x.to.lat],
					getSourceColor: (x: MapData) => x.rgbColor,
					getTargetColor: (x: MapData) => x.rgbColor,
					getHeight: 0.5,
				}),
			);
		}
		if (mapModes?.includes("paths")) {
			layers.push(
				new LineLayer({
					id: "line-layer",
					data: linesData,
					getWidth: 5,
					opacity: 1,
					getSourcePosition: (x: MapData) => [x.from.lng, x.from.lat],
					getTargetPosition: (x: MapData) => [x.to.lng, x.to.lat],
					getColor: (x: MapData) => x.rgbColor,
				}),
			);
		}
		return layers;
	}, [mapModes, linesData, arcsData]);

	const onMapModeArcsChange = useMemo(() => {
		return (checked: boolean) => {
			const newMapModes: MapMode[] = checked
				? [...(mapModes ?? []), "arcs"]
				: (mapModes ?? []).filter((x) => x !== "arcs");
			onMapModesChange?.(newMapModes);
		};
	}, [mapModes, onMapModesChange]);
	const onMapModePathsChange = useMemo(() => {
		return (checked: boolean) => {
			const newMapModes: MapMode[] = checked
				? [...(mapModes ?? []), "paths"]
				: (mapModes ?? []).filter((x) => x !== "paths");
			onMapModesChange?.(newMapModes);
		};
	}, [mapModes, onMapModesChange]);

	return (
		<DeckGL //
			initialViewState={{ longitude: -3, latitude: 57, zoom: 6, pitch: 30 }}
			controller
			layers={layers}
			viewState={viewState}
			onViewStateChange={({ viewState }) =>
				onViewStateChange?.({ ...viewState, index })
			}
		>
			{onMapModesChange && (
				<div
					style={{
						position: "absolute",
						top: 0,
						right: 0,
						background: "white",
						padding: 10,
						borderRadius: 5,
						margin: 10,
						boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
						opacity: 0.3,
						// ":hover": { opacity: 0.8 },
						transition: "opacity 0.2s",
					}}
				>
					<Switch
						size="small"
						checkedChildren="Arcs"
						unCheckedChildren="Arcs"
						onChange={onMapModeArcsChange}
						checked={mapModes?.includes("arcs")}
					/>
					<Divider style={{ margin: "3px 0" }} />
					<Switch
						size="small"
						checkedChildren="Paths"
						unCheckedChildren="Paths"
						onChange={onMapModePathsChange}
						checked={mapModes?.includes("paths")}
					/>
				</div>
			)}
			<GMap //
				mapStyle="mapbox://styles/mapbox/streets-v12"
				mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
			/>
			{/* TODO: beautify the path lines of drivers, with stops numbers */}
			{/* TODO: Add tooltip on trips and all */}
		</DeckGL>
	);
};
