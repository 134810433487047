import { Modal, Space, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import type { EvaluationResult, Trip } from "../../server/ai/evaluationResult";
import type { ScenarioRequest } from "../../server/ai/scenarioRequest";
import { useFetchScorers } from "../client";
import { Dashboard } from "./Dashboard";
import { Parameters } from "./Parameters";
import { rgbColorToHex } from "./colors";
import {
	type DisplayMode,
	camelCaseToWords,
	fetchAllTrips,
	fetchAllTripsIgnoreFails,
	fetchScenarios,
	flattenEvaluationResults,
	getAllVehicles,
} from "./common";

export const AppComparator = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [disableParameters, setDisableParameters] = useState(false);
	const [triggerScenarioRefresh, setTriggerScenarioRefresh] = useState(0);
	const [selectedDates, setSelectedDates] = useState<[Date, Date] | null>([
		new Date(),
		new Date(),
	]);
	const [scenarioRequests, setScenarioRequests] = useState<ScenarioRequest[]>(
		[],
	);
	const [evaluationResults, setEvaluationResults] = useState<
		[EvaluationResult[], EvaluationResult[]]
	>([[], []]); // [originalEvaluationResult, aiEvaluationResult]
	const [allTrips, setAllTrips] = useState<[Trip[][], Trip[][]]>([[], []]); // [originalTrips, aiTrips]
	const aiGoals = useFetchScorers().data ?? [];
	const [displayMode, setDisplayMode] = useState<DisplayMode>("value");
	const scorers = useMemo(
		() =>
			aiGoals
				.sort()
				.map((x) => ({ label: `Optimise ${camelCaseToWords(x)}`, value: x })),
		[aiGoals],
	);
	const [selectedScorer, setSelectedScorer] = useState<string | null>(null);
	const allVehicles = useMemo(
		() => getAllVehicles(scenarioRequests),
		[scenarioRequests],
	);
	const vehicles = useMemo(
		() =>
			allVehicles
				.map((x) => ({
					label: x.name,
					value: x.id,
					color: rgbColorToHex(x.rgbColor ?? [0, 0, 0]),
				}))
				.sort((a, b) => a.label.localeCompare(b.label)) ?? [],
		[allVehicles],
	);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<string[]>([]);
	const flatTrips = useMemo(
		(): [Trip[], Trip[]] => [allTrips[0].flat(), allTrips[1].flat()],
		[allTrips],
	);
	const flatEvaluationResults = useMemo(
		(): [EvaluationResult, EvaluationResult] => [
			flattenEvaluationResults(evaluationResults[0]),
			flattenEvaluationResults(evaluationResults[1]),
		],
		[evaluationResults],
	);

	useEffect(() => {
		(async () => {
			// AI
			if (!scenarioRequests) {
				setAllTrips((x) => [x[0], []]);
				setEvaluationResults((x) => [x[0], []]);
				return;
			}
			const scenariosWithFilteredVehicles: ScenarioRequest[] =
				scenarioRequests.map((scenario) => ({
					...scenario,
					scorer: selectedScorer
						? (selectedScorer as ScenarioRequest["scorer"])
						: scenario.scorer,
					vehicles:
						selectedVehicleIds.length !== 0
							? scenario.vehicles.filter((f) =>
									selectedVehicleIds.includes(f.id),
								)
							: scenario.vehicles,
				}));
			setDisableParameters(true);
			try {
				messageApi.open({
					key: "ai-trips",
					type: "loading",
					duration: 0,
					content: "Loading AI Trips...",
				});
				const results = await fetchAllTripsIgnoreFails(
					scenariosWithFilteredVehicles,
				);
				setAllTrips((x) => [x[0], results.map((x) => x.trips ?? [])]);
				setEvaluationResults((x) => [x[0], results]);
			} catch (error) {
				console.error(error);
				messageApi.error(`Failed to fetch ai trips: ${error}`);
			}
			messageApi.destroy("ai-trips");
			setDisableParameters(false);
		})();
	}, [scenarioRequests, selectedVehicleIds, messageApi, selectedScorer]);
	useEffect(() => {
		(async () => {
			// ORIGINAL
			if (!scenarioRequests) {
				setAllTrips((x) => [[], x[1]]);
				setEvaluationResults((x) => [[], x[1]]);
				return;
			}
			try {
				messageApi.open({
					key: "original-trips",
					type: "loading",
					duration: 0,
					content: "Loading Original Trips...",
				});
				const results = await fetchAllTrips(scenarioRequests, true).finally(
					() => messageApi.destroy("original-trips"),
				);
				setAllTrips((x) => [results.map((x) => x.trips ?? []), x[1]]);
				setEvaluationResults((x) => [results, x[1]]);
			} catch (error) {
				console.error(error);
				messageApi.error(`Failed to fetch original trips: ${error}`);
			}
		})();
	}, [scenarioRequests, messageApi]);
	useEffect(() => {
		triggerScenarioRefresh;
		(async () => {
			if (!selectedDates) return setScenarioRequests([]);
			setAllTrips(() => [[], []]);
			setEvaluationResults(() => [[], []]);
			const scenarios = await fetchScenarios(selectedDates);
			setScenarioRequests(selectedDates ? scenarios : []);
		})();
	}, [selectedDates, triggerScenarioRefresh]);
	useEffect(() => {
		(async () => {
			setAllTrips((e) => [e[0], []]);
			setEvaluationResults((e) => [e[0], []]);
		})();
	}, []);
	const setVehicleIdsChange = (vehicleIds: string[]) => {
		setAllTrips((e) => [e[0], []]);
		setEvaluationResults((e) => [e[0], []]);
		setSelectedVehicleIds(vehicleIds);
	};
	const showMoreSettingsModal = () => {
		Modal.info({
			title: "More settings",
			closable: true,
			content: (
				<div>
					<p>Coming soon...</p>
				</div>
			),
			onOk() {
				console.log("OK");
			},
		});
	};
	return (
		<>
			{contextHolder}
			<Space direction="vertical" style={{ display: "flex" }}>
				<Parameters
					selectedDate={selectedDates}
					onDateChange={setSelectedDates}
					vehicles={vehicles}
					onVehicleIdsChange={setVehicleIdsChange}
					scorers={scorers}
					onScorerChange={setSelectedScorer}
					displayMode={displayMode}
					onDisplayModeChange={setDisplayMode}
					onRefreshScenario={() => setTriggerScenarioRefresh((x) => x + 1)}
					onMoreSettingsClick={() => showMoreSettingsModal()}
					disabled={disableParameters}
				/>
				<Dashboard //
					evaluationResults={flatEvaluationResults}
					allTrips={flatTrips}
					displayMode={displayMode}
				/>
			</Space>
		</>
	);
};
