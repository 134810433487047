import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import {
	Outlet,
	RouterProvider,
	createRootRoute,
	createRoute,
	createRouter,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { App } from "./App";
import { queryClient } from "./client";
import { AppComparator } from "./comparator/AppComparator";
import { FullScreenLayout } from "./comparator/FullScreenLayout";

const localStoragePersister = createSyncStoragePersister({
	storage: window.localStorage,
});
persistQueryClient({ queryClient, persister: localStoragePersister });
const rootRoute = createRootRoute({
	component: () => (
		<QueryClientProvider client={queryClient}>
			<FullScreenLayout
				menuItems={
					routeTree.children?.map((x) => ({
						key: x.fullPath,
						label: x.fullPath,
					})) ?? []
				}
			>
				<Outlet />
				<TanStackRouterDevtools />
				<ReactQueryDevtools />
			</FullScreenLayout>
		</QueryClientProvider>
	),
});

const indexRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/",
	component: App,
});

const aiRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/ai",
	component: AppComparator,
});

const aboutRoute = createRoute({
	getParentRoute: () => rootRoute,
	path: "/about",
	component: () => "Hello from About!",
});

const routeTree = rootRoute.addChildren([indexRoute, aiRoute, aboutRoute]);

const router = createRouter({ routeTree });

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

export const AppRouter = () => <RouterProvider router={router} />;
