import { RedoOutlined, SettingOutlined } from "@ant-design/icons";
import { Col, Form, Radio, Row, Select, Space, Tooltip } from "antd";
import { useMemo } from "react";
import { DatePicker } from "./DatePicker";
import { type DisplayMode, allDisplayMode, camelCaseToWords } from "./common";

export type Option = {
	label: string;
	value: string;
};

export type OptionWithColor = Option & {
	color?: string;
};

export type ParametersProps = {
	selectedDate: [Date, Date] | null;
	onDateChange: (dates: [Date, Date] | null) => void;
	vehicles: OptionWithColor[];
	onVehicleIdsChange: (vehicles: string[]) => void;
	scorers: Option[];
	onScorerChange: (aiType: string) => void;
	displayMode: DisplayMode;
	onDisplayModeChange: (displayMode: DisplayMode) => void;
	onRefreshScenario: () => void;
	onMoreSettingsClick: () => void;
	disabled?: boolean;
};

export const Parameters = ({
	onDateChange,
	selectedDate,
	vehicles,
	scorers,
	onVehicleIdsChange,
	onScorerChange,
	displayMode,
	onDisplayModeChange,
	onRefreshScenario,
	onMoreSettingsClick,
	disabled,
}: ParametersProps) => {
	const scenarioLabel = useMemo(
		() => (
			<Space>
				<span>Scenario</span>
				<Tooltip
					mouseEnterDelay={0}
					mouseLeaveDelay={0}
					title="Refresh scenario"
				>
					<RedoOutlined onClick={onRefreshScenario} />
				</Tooltip>
				<Tooltip mouseEnterDelay={0} mouseLeaveDelay={0} title="More settings">
					<SettingOutlined onClick={onMoreSettingsClick} />
				</Tooltip>
			</Space>
		),
		[onRefreshScenario, onMoreSettingsClick],
	);
	return (
		<Form layout="vertical" disabled={disabled}>
			<Row gutter={16}>
				<Col span={4} xs={24} sm={4}>
					<Form.Item label={scenarioLabel}>
						<DatePicker.RangePicker
							key={JSON.stringify({ selectedDate })}
							format={"yyyy-MM-dd"}
							style={{ width: "100%" }}
							value={selectedDate}
							onChange={(x) => {
								const start = x?.[0];
								const end = x?.[1];
								if (!start || !end) return null;
								return onDateChange?.([start, end]);
							}}
							allowClear={false}
						/>
					</Form.Item>
				</Col>
				<Col span={10} xs={24} sm={10}>
					<Form.Item label="Vehicles">
						<Select //
							key={JSON.stringify(vehicles)}
							mode="multiple"
							defaultValue={vehicles?.map((vehicle) => vehicle.value)}
							onChange={onVehicleIdsChange}
							maxTagCount="responsive"
							disabled={!vehicles?.length || disabled}
						>
							{vehicles?.map((vehicle) => (
								<Select.Option key={vehicle.value} value={vehicle.value}>
									<span
										style={{
											textDecoration: "underline",
											textDecorationColor: vehicle.color,
										}}
									>
										{vehicle.label}
									</span>
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={4} xs={24} sm={4}>
					<Form.Item label="AI goal">
						<Select //
							key={JSON.stringify(scorers)}
							defaultValue={scorers?.[0]?.value}
							options={scorers}
							onChange={onScorerChange}
							disabled={!scorers?.length || disabled}
						/>
					</Form.Item>
				</Col>
				<Col span={6} xs={24} sm={6}>
					<Form.Item label="Display mode">
						<Radio.Group
							value={displayMode}
							onChange={(e) => onDisplayModeChange(e.target.value)}
							disabled={false}
						>
							{allDisplayMode.map((x) => (
								<Radio.Button key={x} value={x}>
									{camelCaseToWords(x)}
								</Radio.Button>
							))}
						</Radio.Group>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};
