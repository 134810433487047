import { Card } from "antd";
import { useMemo, useState } from "react";
import {
	Legend,
	PolarAngleAxis,
	PolarGrid,
	Radar,
	RadarChart,
	ResponsiveContainer,
	Tooltip,
} from "recharts";
import type {
	Evaluation,
	EvaluationResult,
} from "../../server/ai/evaluationResult";

type KpiRadarChartProps = {
	results: [EvaluationResult | null, EvaluationResult | null];
};

const kv: Record<
	keyof Omit<Evaluation, "revenue" | "expenses" | "hoursTraveled">,
	string
> = {
	vehicles: "Vehicles",
	distanceTraveled: "Distance traveled",
	hoursWorked: "Hours worked",
	fuelConsumption: "Fuel consumed",
	fuelCost: "Fuel cost",
	maintenanceCost: "Maintenance cost",
	resourceCost: "Resource cost",
	driverWage: "Driver wage",
	profit: "Profit",
};
const toPercent = (a: number, b: number) => {
	if (b === 0) return 0;
	const max = Math.max(a, b);
	return Math.round((a / max) * 100);
};
const getData = (
	originalResult: Evaluation | undefined,
	aiResult: Evaluation | undefined,
	kpi: string,
	key: keyof Evaluation,
) => {
	const original = toPercent(originalResult?.[key] ?? 0, aiResult?.[key] ?? 0);
	const ai = toPercent(aiResult?.[key] ?? 0, originalResult?.[key] ?? 0);
	return { kpi, original, ai };
};

// biome-ignore lint/suspicious/noExplicitAny: I'm just lazy to type this, TODO
const CustomTooltip = ({ active, payload, label }: any) => {
	if (!active || !payload || !payload.length) return null;
	return (
		<Card bordered={false}>
			<p>{`Original: ${payload[0].value}`}</p>
			<p>{`AI: ${payload[1].value}`}</p>
			<p>{label}</p>
		</Card>
	);
};
export const KpiRadarChart = ({ results }: KpiRadarChartProps) => {
	const [originalOpacity, setOriginalOpacity] = useState(0.3);
	const [aiOpacity, setAiOpacity] = useState(0.3);
	const originalResult = results[0]?.totals;
	const aiResult = results[1]?.totals;
	const data = useMemo(
		() =>
			Object.keys(kv).map((key: string) =>
				getData(
					originalResult,
					aiResult,
					kv[key as keyof typeof kv],
					key as keyof Evaluation,
				),
			),
		[originalResult, aiResult],
	);
	const handleOpacity = (key: string, opacity: number) => {
		if (key === "ai") setOriginalOpacity(opacity);
		else if (key === "original") setAiOpacity(opacity);
	};
	return (
		<ResponsiveContainer width="100%" height="100%">
			<RadarChart cx="50%" cy="50%" outerRadius="90%" data={data}>
				<PolarGrid />
				<PolarAngleAxis dataKey="kpi" />
				<Radar
					name="Original"
					dataKey="original"
					stroke="#d88484"
					fill="#d88484"
					fillOpacity={originalOpacity}
					strokeWidth={3}
				/>
				<Radar
					name="AI"
					dataKey="ai"
					stroke="#82ca9d"
					fill="#82ca9d"
					fillOpacity={aiOpacity}
					strokeWidth={3}
				/>
				<Legend
					onMouseEnter={({ dataKey }) => handleOpacity(dataKey as string, 0)}
					onMouseLeave={({ dataKey }) => handleOpacity(dataKey as string, 0.3)}
				/>
				<Tooltip content={<CustomTooltip />} />
			</RadarChart>
		</ResponsiveContainer>
	);
};
