export const rgbColors: [number, number, number][] = [
	[64, 203, 90],
	[236, 86, 148],
	[144, 59, 186],
	[105, 180, 38],
	[151, 101, 227],
	[162, 198, 53],
	[82, 113, 239],
	[209, 181, 36],
	[61, 83, 198],
	[183, 192, 53],
	[114, 79, 190],
	[74, 166, 49],
	[213, 104, 228],
	[130, 207, 98],
	[179, 50, 163],
	[63, 197, 117],
	[232, 62, 156],
	[40, 154, 68],
	[230, 96, 196],
	[96, 179, 85],
	[195, 127, 234],
	[85, 140, 40],
	[58, 108, 215],
	[233, 165, 35],
	[98, 87, 184],
	[231, 141, 15],
	[69, 142, 232],
	[202, 61, 20],
	[72, 209, 153],
	[185, 44, 129],
	[87, 180, 117],
	[200, 44, 111],
	[57, 116, 33],
	[160, 90, 188],
	[145, 170, 57],
	[131, 134, 237],
	[195, 176, 68],
	[86, 99, 186],
	[222, 159, 68],
	[52, 103, 172],
	[221, 123, 47],
	[80, 80, 153],
	[169, 133, 36],
	[107, 79, 160],
	[148, 179, 93],
	[131, 59, 145],
	[117, 127, 31],
	[220, 133, 209],
	[60, 139, 81],
	[226, 54, 80],
	[94, 204, 183],
	[236, 90, 58],
	[72, 191, 224],
	[178, 54, 43],
	[61, 163, 144],
	[217, 60, 101],
	[46, 133, 104],
	[238, 118, 170],
	[35, 94, 49],
	[171, 85, 160],
	[81, 102, 21],
	[141, 117, 195],
	[97, 97, 23],
	[178, 149, 223],
	[180, 82, 28],
	[82, 151, 205],
	[240, 118, 94],
	[26, 100, 71],
	[226, 112, 126],
	[61, 119, 72],
	[179, 75, 121],
	[137, 181, 118],
	[131, 81, 140],
	[87, 126, 63],
	[225, 133, 173],
	[71, 96, 37],
	[157, 164, 228],
	[120, 100, 27],
	[115, 115, 174],
	[153, 143, 67],
	[83, 85, 139],
	[192, 182, 116],
	[144, 75, 115],
	[151, 109, 38],
	[195, 136, 183],
	[118, 81, 19],
	[239, 160, 179],
	[98, 97, 44],
	[173, 65, 80],
	[137, 131, 77],
	[142, 68, 93],
	[240, 165, 121],
	[138, 67, 33],
	[199, 148, 96],
	[155, 82, 81],
	[166, 99, 40],
	[216, 128, 123],
	[137, 95, 54],
	[208, 114, 82],
	[167, 99, 67],
];

export const rgbColorToHex = (rgb: [number, number, number]) => {
	const [r, g, b] = rgb;
	return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
};
