import { Empty, Row } from "antd";
import type { EvaluationResult } from "../../server/ai/evaluationResult";
import { KpiTile } from "./KpiTile";
import type { DisplayMode } from "./common";

type KpiResultProps = {
	results: [EvaluationResult | null, EvaluationResult | null];
	index: number;
	showTooltip?: boolean;
	displayMode?: DisplayMode;
};

const titleWithTooltip = {
	vehicles: {
		title: "Vehicles",
		tooltip:
			"A vehicle represent a pair of driver, truck and trailer. This is the number of vehicles that are used in the simulation.",
	},
	hoursTraveled: {
		title: "Hours traveled",
		tooltip:
			"The total number of hours traveled by all the vehicles in the simulation.",
	},
	distanceTraveled: {
		title: "Distance traveled",
		tooltip:
			"The total number of miles traveled by all the vehicles in the simulation.",
	},
	hoursWorked: {
		title: "Hours worked",
		tooltip:
			"The total number of hours worked by all the vehicles in the simulation.",
	},
	fuelConsumption: {
		title: "Fuel consumed",
		tooltip:
			"The total number of liters of fuel consumed by all the vehicles in the simulation.",
	},
	fuelCost: {
		title: "Fuel cost",
		tooltip:
			"The total cost of fuel consumed by all the vehicles in the simulation.",
	},
	maintenanceCost: {
		title: "Maintenance cost",
		tooltip:
			"The total cost of maintenance of all the vehicles in the simulation.",
	},
	resourceCost: {
		title: "Resource cost",
		tooltip:
			"The total cost of resources consumed by all the vehicles in the simulation.",
	},
	driverWage: {
		title: "Driver wage",
		tooltip:
			"The total cost of driver wages for all the vehicles in the simulation.",
	},
	expenses: {
		title: "Expenses",
		tooltip:
			"The total cost of expenses for all the vehicles in the simulation. This includes fuel, maintenance, resources and driver wages.",
	},
	revenue: {
		title: "Revenue",
		tooltip:
			"The total revenue for all the vehicles in the simulation. This is the sum of all the costs charged to the customers.",
	},
	profit: {
		title: "Profit",
		tooltip:
			"The total profit for all the vehicles in the simulation. This is the revenue minus the expenses.",
	},
};

export const KpiTiles = ({
	results,
	index,
	displayMode = "value",
	showTooltip = false,
}: KpiResultProps) => {
	const result = results[index]?.totals;
	const otherResult = results[1 - index]?.totals;
	const getProps = (key: keyof typeof titleWithTooltip) => {
		const { title, tooltip } = titleWithTooltip[key];
		const value = result?.[key] ?? 0;
		const other = otherResult?.[key] ?? 0;
		return {
			title,
			tooltip: showTooltip ? tooltip : undefined,
			value,
			other,
			displayMode,
		};
	};
	if (!result) return <Empty />;
	return (
		<Row gutter={[16, 16]}>
			<KpiTile {...getProps("vehicles")} decimals={0} />
			<KpiTile {...getProps("hoursTraveled")} suffix="h" />
			<KpiTile {...getProps("distanceTraveled")} suffix="mi" />
			<KpiTile {...getProps("hoursWorked")} suffix="h" />
			<KpiTile {...getProps("fuelConsumption")} suffix="l" />
			<KpiTile {...getProps("fuelCost")} suffix="£" />
			<KpiTile {...getProps("maintenanceCost")} suffix="£" />
			<KpiTile {...getProps("resourceCost")} suffix="£" />
			<KpiTile {...getProps("driverWage")} suffix="£" />
			<KpiTile {...getProps("revenue")} suffix="£" />
			<KpiTile {...getProps("profit")} suffix="£" reverse reverseArrow />
		</Row>
	);
};
