import { useFetchLegs } from "./client";
import { useAsLocations } from "./useAsLocations";
import { useDateRangeSelector } from "./useDateRangeSelector";
import { useSelectBusinessUnit } from "./useSelectBusinessUnit";

export const useTopMapControls = () => {
	const [buSelector, businessUnit] = useSelectBusinessUnit();
	const [dateRangeSelector, dateFrom, dateTo] = useDateRangeSelector();
	const {
		data: legs,
		refetch,
		isFetching,
	} = useFetchLegs(businessUnit?.id, dateFrom, dateTo);
	const locations = useAsLocations(legs);
	const element = (
		<div
			style={{
				display: "flex",
				gap: 16,
				backgroundColor: "lightgray",
				padding: 8,
				justifyContent: "space-evenly",
			}}
		>
			{buSelector}
			<div style={{ display: "flex", gap: 8 }}>
				{dateRangeSelector}
				<button type="button" onClick={() => refetch()}>
					{isFetching ? "⏬" : "🔃"}
				</button>
			</div>
			<div>Legs: {legs.length}</div>
			<div>Distinct locations: {locations.length}</div>
		</div>
	);
	return [element, legs, locations] as const;
};
