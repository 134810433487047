import { ConfigProvider } from "antd";
import locale from "antd/locale/en_GB";
import { createRoot } from "react-dom/client";
import { AppRouter } from "./AppRouter.tsx";

const root = document.getElementById("root");
if (!root) throw new Error("Root element not found");
createRoot(root).render(
	<ConfigProvider locale={locale}>
		<AppRouter />
	</ConfigProvider>,
);
