import {
	ArrowDownOutlined,
	ArrowUpOutlined,
	InfoCircleOutlined,
	SwapOutlined,
} from "@ant-design/icons";
import { Card, Col, Statistic, Tooltip } from "antd";
import { useMemo } from "react";
import CountUp from "react-countup";
import type { DisplayMode } from "./common";

type KpiProps = {
	title: string;
	tooltip?: string;
	value: number;
	other: number | undefined;
	decimals?: number;
	suffix?: string;
	reverse?: boolean;
	reverseArrow?: boolean;
	displayMode: DisplayMode;
};
export const KpiTile = ({
	title,
	value,
	tooltip,
	decimals = 1,
	suffix,
	other,
	displayMode,
	reverse = false,
	reverseArrow = false,
}: KpiProps) => {
	other ??= Number.MAX_VALUE;
	let isBetter = value === other ? null : value < other;
	if (reverse && isBetter != null) isBetter = !isBetter;
	const color = isBetter != null ? (isBetter ? "green" : "red") : "black";
	let prefix =
		isBetter != null ? (
			isBetter ? (
				<ArrowDownOutlined />
			) : (
				<ArrowUpOutlined />
			)
		) : (
			<SwapOutlined />
		);
	if (reverseArrow)
		prefix =
			isBetter != null ? (
				isBetter ? (
					<ArrowUpOutlined />
				) : (
					<ArrowDownOutlined />
				)
			) : (
				<SwapOutlined />
			);
	if (displayMode === "percent") suffix = "%";

	const titleElement = (
		<>
			{title}
			{tooltip && (
				<>
					{" "}
					<Tooltip title={tooltip}>
						<InfoCircleOutlined />
					</Tooltip>
				</>
			)}
		</>
	);
	const actualValue = useMemo(() => {
		switch (displayMode) {
			case "value":
				return value;
			case "percent":
				return other ? -(100 - (value / other) * 100) : 0;
			case "delta":
				return value - (other ?? 0);
		}
	}, [displayMode, value, other]);
	return (
		<Col lg={6} md={8} sm={12} xs={24}>
			<Card bordered={false} style={{ height: "100%" }}>
				<Statistic //
					title={titleElement}
					value={actualValue}
					suffix={suffix}
					valueStyle={{ color }}
					prefix={prefix}
					formatter={(val) => (
						<CountUp end={val as number} duration={0.3} decimals={decimals} />
					)}
				/>
			</Card>
		</Col>
	);
};
