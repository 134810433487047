import { useEffect, useState } from "react";
import { type BusinessUnit, useFetchBusinessUnits } from "./client";

export const useSelectBusinessUnit = () => {
	const [businessUnit, setBusinessUnit] = useState<BusinessUnit>();
	const { data: businessUnits } = useFetchBusinessUnits();

	useEffect(() => {
		if (businessUnits?.[0]) setBusinessUnit(businessUnits[0]);
	}, [businessUnits]);

	const buSelector = (
		<select
			value={businessUnit?.id}
			onChange={(e) => {
				const id = Number(e.target.value);
				setBusinessUnit(businessUnits.find((bu) => bu.id === id));
			}}
		>
			<option value="">Select a business unit...</option>
			{businessUnits.map((bu) => (
				<option key={bu.id} value={bu.id}>
					{bu.name}
				</option>
			))}
		</select>
	);

	return [buSelector, businessUnit] as const;
};
