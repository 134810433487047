import { uniqBy } from "lodash";
import { useMemo } from "react";
import type { Leg } from "./client";

export const useAsLocations = (legs: Leg[]) => {
	const locations = useMemo(
		() =>
			uniqBy(
				legs.flatMap((leg) => [leg.collection, leg.delivery]),
				(x) => x.name,
			),
		[legs],
	);
	return locations;
};
