import { treaty } from "@elysiajs/eden";
import { QueryClient, useQuery } from "@tanstack/react-query";
import type { Server } from "../server/server";

export const queryClient = new QueryClient();
export let clientDomain = "localhost:4001";
const injectedApiUrl = "https://ai-uat-be.celerum.online";
if (injectedApiUrl.startsWith("http")) clientDomain = injectedApiUrl;
const client = treaty<Server>(clientDomain);

export type BusinessUnit = ReturnType<
	typeof useFetchBusinessUnits
>["data"][number];
export const useFetchBusinessUnits = () =>
	useQuery({
		queryKey: ["businessUnits"],
		queryFn: () => client.businessUnits.get().then((x) => x.data ?? []),
		initialData: [],
	});

export type Leg = ReturnType<typeof useFetchLegs>["data"][number];
export type Location = Leg["collection"]; // same as Leg["delivery"]
export const useFetchLegs = (
	businessUnitId: string | number | undefined,
	fromDate: Date,
	toDate: Date,
) =>
	useQuery({
		queryKey: ["legs", businessUnitId, fromDate, toDate],
		queryFn: async () => {
			if (!businessUnitId) return [];
			return client.legs
				.post({
					businessUnitId: `${businessUnitId}`,
					fromDate,
					toDate,
				})
				.then((x) => x.data ?? []);
		},
		initialData: [],
	});

export const useFetchEvaluationResults = (
	businessUnitId: string | number | undefined,
	date: Date,
) =>
	useQuery({
		queryKey: ["evaluationResults", businessUnitId, date],
		queryFn: async () => {
			if (!businessUnitId) return null;
			return client.solve
				.post({
					businessUnitId: `${businessUnitId}`,
					date,
				})
				.then((x) => x.data);
		},
	});

export const useFetchScorers = () =>
	useQuery({
		queryKey: ["scorers"],
		queryFn: () => client.scorers.get().then((x) => x.data),
		initialData: [],
	});
