import type { ViewStateChangeParameters } from "@deck.gl/core";
import { Col, Divider, Row, Space } from "antd";
import _ from "lodash";
import { useState } from "react";
import type { EvaluationResult, Trip } from "../../server/ai/evaluationResult";
import { KpiDriversTable } from "./KpiDriversTable";
import { KpiRadarChart } from "./KpiRadarChart";
import { KpiTiles } from "./KpiTiles";
import { TripsMap } from "./TripsMap";
import type { DisplayMode, MapMode } from "./common";

export type DashboardProps = {
	allTrips: [Trip[], Trip[]];
	evaluationResults: [EvaluationResult | null, EvaluationResult | null];
	displayMode: DisplayMode;
};

export const Dashboard = ({
	allTrips,
	evaluationResults,
	displayMode,
}: DashboardProps) => {
	const [viewState, setViewState] = useState<
		ViewStateChangeParameters["viewState"] | undefined
	>();
	const [mapModes, setMapModes] = useState<MapMode[]>(["paths"]);
	const throttledSetViewState = _.debounce(setViewState, 100);
	return (
		<Row gutter={[16, 16]}>
			<Col xs={12}>
				<Divider orientation="left" plain style={{ margin: 0 }}>
					Original
				</Divider>
				<Space direction="vertical" style={{ display: "flex" }}>
					<div style={{ position: "relative", height: "400px" }}>
						<TripsMap //
							allTrips={allTrips}
							index={0}
							viewState={viewState?.index ? viewState : undefined}
							onViewStateChange={throttledSetViewState}
							mapModes={mapModes}
							onMapModesChange={setMapModes}
						/>
					</div>
					<KpiTiles results={evaluationResults} index={0} showTooltip />
				</Space>
			</Col>
			<Col xs={12}>
				<Divider orientation="left" plain style={{ margin: 0 }}>
					AI Optimised
				</Divider>
				<Space direction="vertical" style={{ display: "flex" }}>
					<div style={{ position: "relative", height: "400px" }}>
						<TripsMap //
							allTrips={allTrips}
							index={1}
							viewState={!viewState?.index ? viewState : undefined}
							onViewStateChange={throttledSetViewState}
							mapModes={mapModes}
						/>
					</div>
					<KpiTiles
						results={evaluationResults}
						index={1}
						displayMode={displayMode}
					/>
				</Space>
			</Col>
			<Col xs={24} style={{ height: "500px" }}>
				<KpiRadarChart results={evaluationResults} />
			</Col>
			<Col xs={12}>
				<KpiDriversTable results={evaluationResults} index={0} />
			</Col>
			<Col xs={12}>
				<KpiDriversTable
					results={evaluationResults}
					index={1}
					displayMode={displayMode}
				/>
			</Col>
		</Row>
	);
};
