import { useDateSelector } from "./useDateSelector";

export const useDateRangeSelector = () => {
	const [fromSelector, dateFrom] = useDateSelector({ label: "From" });
	const [toSelector, dateTo] = useDateSelector({ label: "To", isStart: false });
	const selector = (
		<>
			{fromSelector}
			{toSelector}
		</>
	);
	return [selector, dateFrom, dateTo] as const;
};
